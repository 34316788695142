import React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import { useForm } from "react-hook-form"

import "./styles.scss"

import Layout from "../components/layout"
import SEO from "../components/seo"

class Kontakt extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            anliegen: '',
            kursArt: '',
            werk: '',
        };

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {
        if (typeof window === 'undefined') {
            return;
        }
        if (this.props.location.state.typeRequest) {
            this.setState(() => ({ anliegen: this.props.location.state.typeRequest }));
        }
        if (this.props.location.state.typeCourse) {
            this.setState(() => ({ kursArt: this.props.location.state.typeCourse }));
        }
        if (this.props.location.state.typeWerk) {
            this.setState(() => ({ werk: this.props.location.state.typeWerk }));
        }
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }



    render() {
        return (
            <Layout>
                <SEO title="Home" />
                <section className="section">
                    <form name="Contact Form" method="post" data-netlify="true" action="/">
                        <input name="form-name" value="Contact Form" type="hidden" />
                        <div class="field">
                            <label class="label">Wofür interessierts du dich?</label>
                            <div class="control">
                                <div class="select">
                                    <select name="anliegen" value={this.state.anliegen} onChange={this.handleInputChange}>
                                        <option name="allgemein" value="allgemein">Allgemein</option>
                                        <option name="nähkurs" value="nähkurs">Nähkurs</option>
                                        <option name="werke" value="werke">Hand(K)machtes</option>
                                        <option name="dekoration" value="dekoration">Dekoration</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className={"field " + (this.state.anliegen === "nähkurs" ? " " : "is-hidden")}>
                            <label class="label">Welcher Kurs</label>
                            <div class="control">
                                <div class="select">
                                    <select value={this.state.kursArt} name="kursArt" onChange={this.handleInputChange}>
                                        <option value="default" name="---">---</option>
                                        {this.props.data.kurse.edges.map(({ node }) => (
                                            <option name={node.frontmatter.title} value={node.frontmatter.title}>{node.frontmatter.title} am: {node.frontmatter.date}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className={"field " + (this.state.anliegen === "werke" ? " " : "is-hidden")}>
                            <label class="label">Welches Teil</label>
                            <div class="control">
                                <div class="select">
                                    <select value={this.state.werk} name="werkArt" onChange={this.handleInputChange}>
                                        <option value="default" name="---">---</option>
                                        {this.props.data.werke.edges.map(({ node }) => (
                                            <option name={node.frontmatter.title + node.frontmatter.werk_id} value={node.frontmatter.title + node.frontmatter.werk_id}>{node.frontmatter.title}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="field">
                            <label class="label">Vorname</label>
                            <div class="control">
                                <input class="input" type="text" placeholder="Text input" name="vorname" />
                            </div>
                        </div>
                        <div class="field">
                            <label class="label">Nachname</label>
                            <div class="control">
                                <input class="input" type="text" placeholder="Text input" name="nachname" />
                            </div>
                        </div>
                        <div class="field">
                            <label class="label">Email-Adresse</label>
                            <div class="control">
                                <input class="input" type="email" placeholder="Email input" name="email" />
                            </div>
                        </div>
                        <div class="field">
                            <label class="label">Nachricht</label>
                            <div class="control">
                                <textarea class="textarea" placeholder="Deine Nachricht" name="nachricht"></textarea>
                            </div>
                        </div>
                        <div class="field">
                        <label class="checkbox">
                            <input type="checkbox" required name="AGB" /> Ich stimme den <Link to="/agb/" className="">AGBs</Link> zu.
                        </label>
                        </div>
                        <div class="field is-grouped">
                            <div class="control">
                                <button class="button is-primary" type="submit">Anmelden</button>
                            </div>
                            <div class="control">
                                <button class="button is-link is-light">Abbrechen</button>
                            </div>
                        </div>
                    </form>
                </section>
            </Layout>
        );

    }
}

export const query = graphql`
query {
  kurse: allMarkdownRemark(filter: {frontmatter: {category: {eq: "kurs"}}}) {
    edges {
      node {
        html
        frontmatter {
          id
          title
          level
          price
          duration
          date
        }
      }
    }
  }
  werke: allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/markdown-werke/" }  }) {
    edges {
      node {
        html
        frontmatter {
          werk_id
          title
          price
        }
      }
    }
  }
}
  `

export default Kontakt
